<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="1500px">
      <v-card elevation="8" :loading="loading" class="mx-auto" height="1000">
        <v-card-title>
          <span class="subtitle-2">คู่มือการใช้งาน</span>
          <v-spacer></v-spacer>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>
        <v-container fluid>
          <!-- <vue-friendly-iframe
            src="https://tpcc.police.go.th/man"
            style="height:100%;width:100%;display:block;z-index:999"
            @load="onLoad"
          ></vue-friendly-iframe> -->
          <iframe src="https://tpcc.police.go.th/man" style="height:80vh;width:100%;display:block;border:none" />
        </v-container>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  methods: {
    open() {
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    onLoad() {
      this.loading = false
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      manSrc: 'https://tpcc.police.go.th/man',
    }
  },
}
</script>

<style></style>
