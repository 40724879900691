<template>
  <v-container class="fill-height">
    <v-row justify="center" align="center">
      <v-col align-self="center" class="d-flex justify-center">
        <v-card elevation="5" max-width="700">
          <v-card-title primary-title>
            <v-icon color="orange">
              mdi-traffic-cone
            </v-icon>
            ระบบควบคุมการตั้งจุดตรวจ
          </v-card-title>
          <v-card-subtitle>
            Thai Police Checkpoint Control
          </v-card-subtitle>
          <v-card-text>
            พัฒนาระบบโดย คณะทำงานนโยบายการพัฒนางานเทคโนโลยีและการสื่อสาร
          </v-card-text>
          <v-list subheader two-line>
            <v-list-item @click="openManDialog">
              <v-list-item-avatar>
                <v-icon class="green darken-1" dark small>
                  mdi-book-education
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                อ่านคู่มือ
              </v-list-item-content>
            </v-list-item>
            <v-subheader inset>Downloads</v-subheader>

            <v-list-item v-for="f in files" :key="f.name">
              <v-list-item-avatar>
                <v-icon class="grey lighten-1" dark small>
                  mdi-file
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ f.name }}</v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon :href="f.link" target="_blank">
                  <v-icon color="grey lighten-1">mdi-download</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <man-dialog ref="man"></man-dialog>
  </v-container>
</template>

<script>
import manDialog from './mandialog'
export default {
  methods: {
    openManDialog() {
      this.$refs.man.open()
    },
  },
  data() {
    return {
      logo: () => import('@/assets/logo.png'),
      files: [
        { name: 'คู่มือการใช้งาน', link: 'https://1drv.ms/u/s!Ag0ZeBSbNY1ThaRAozj-9eet4u64YQ?e=eQXufj' },
        { name: 'โปรแกรมอ่านบัตรประชาชน', link: 'https://1drv.ms/u/s!Ag0ZeBSbNY1ThaRB80nHhIYzsdVUVA?e=8LTXDb' },
      ],
    }
  },
  components: {
    manDialog,
  },
}
</script>
